import React, { useState } from "react";
import deleteIcon from "./Delete-Button.png";
import { Box, Dialog } from "@mui/material";
import { Button } from "../../stories/Button";
import ErrorIcon from "@mui/icons-material/Error";

function DeleteButton({ onDelete }: { onDelete: () => void }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth={"lg"}
        className="delete-dialog"
        onClose={() => setIsOpen(false)}
      >
        <Box className="delete-dialog-content">
          <Box className="delete-dialog-inner-content">
            <ErrorIcon
              sx={{ color: "#e8408e", height: "72px", width: "72px" }}
            />
            <p className="delete-dialog-large-text">
              Are you sure you want to delete this video?
            </p>
            <p className="delete-dialog-small-text">
              The deletion of this video will be permanent.
            </p>
          </Box>
          <Box className="delete-dialog-button-row">
            <Button
              label="OK"
              cssClass="delete-dialog-button"
              primary={true}
              onClick={() => {
                onDelete();
                setIsOpen(false);
              }}
            />
            <Button
              label="Cancel"
              cssClass="delete-dialog-button"
              onClick={() => setIsOpen(false)}
            />
          </Box>
        </Box>
      </Dialog>
      <span
        aria-label="delete"
        onClick={() => setIsOpen(true)}
        className="delete-video-btn"
      >
        <img src={deleteIcon} alt="delete" />
      </span>
    </>
  );
}

export default DeleteButton;

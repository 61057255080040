import React, { FormEvent, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Box, CircularProgress, Link } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { WebAuth } from "auth0-js";
import Swal from "sweetalert2";
import "./LoginForm.css";
import { RootState } from "../../redux/reducers/_root.reducer";
import { PageTitle } from "../../stories/PageTitle";
import { Button } from "../../stories/Button";
import { InputField } from "../../stories/InputField";
import { useValidation } from "../../hooks/useValidation";

interface pageProp {
  setForgotPasswordCheck: (
    value: boolean | ((prevVar: boolean) => boolean)
  ) => void;
}
const LoginForm: React.FC<pageProp> = (props: pageProp) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { validateEmail } = useValidation();
  const errors = useSelector((store: RootState) => store.errors.loginMessage);
  const user = useSelector((store: RootState) => store.user);
  const location = useLocation();

  const webAuth = new WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN ?? "",
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID ?? "",
    redirectUri: `${window.location.origin}/landingpage${location.search}`,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    responseType: "token",
  });

  const login = (event: FormEvent<HTMLFormElement>) => {
    setIsLoggingIn(true);
    event.preventDefault();

    webAuth.login(
      {
        username,
        password,
      },
      (err) => {
        if (err) {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: err.description,
          });
          return;
        }
        setIsLoggingIn(false);
      }
    );
  };

  return user.isLoading ? (
    <CircularProgress size={64} />
  ) : (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      className="loginFormBox"
      sx={{ justifyContent: "flex-start" }}
    >
      <form className="formPanel" onSubmit={login}>
        <PageTitle pageTitle={true} label="Login" />
        <p className="login-form__details">
          Welcome back! Please enter your details.
        </p>
        <InputField
          placeholder="Email Address"
          inputLabel="Email Address"
          type="email"
          name="username"
          required={true}
          backgroundColor="white"
          value={username}
          error={!isLoggingIn && !validateEmail(username)}
          helperText={
            !isLoggingIn && !validateEmail(username)
              ? "Please enter a valid email address"
              : null
          }
          InputProps={{
            onChange: (event) => {
              setUsername(event.target.value);
            },
          }}
        />
        <InputField
          placeholder="Password"
          inputLabel="Password"
          type="password"
          name="password"
          required={true}
          value={password}
          InputProps={{
            onChange: (event) => {
              setPassword(event.target.value);
            },
          }}
        />
        <div className="forgot-password">
          <Link
            className="forgot-password--link"
            onClick={() => props.setForgotPasswordCheck(true)}
          >
            Forgot Password?
          </Link>
        </div>
        <div className="login-form--button-block">
          <Button
            primary={true}
            type="submit"
            label="LOGIN"
            cssClass="login-button"
          />
          <div className="mobile-register">
            Don't have an account?{" "}
            <Link
              onClick={() => {
                history.push(`/registration${location.search}`);
              }}
            >
              Register Now
            </Link>
          </div>
        </div>
      </form>
    </Box>
  );
};

export default LoginForm;

import { put, takeLatest } from "redux-saga/effects";
import axios from "axios";

function* deleteVideo(action) {
  const id = action.payload;
  const accessToken = localStorage.getItem("accessToken");

  try {
    yield axios.delete(`/api/video/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    yield put({ type: "GET_MY_VIDEOS" });
  } catch (error) {
    console.log("delete saga DELETE", error);
  }
}
function* deleteSaga() {
  yield takeLatest("DELETE_VIDEO", deleteVideo);
}
export default deleteSaga;

// imports
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// styling
import "./Connections.css";
import { ToggleButton } from "@mui/material";
import { ToggleButtonGroup } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import { RootState } from "../../redux/reducers/_root.reducer";
import { Button } from "../../stories/Button";

function Connections() {
  const dispatch = useDispatch();

  const connections = useSelector(
    (store: RootState) => store.connectionsReducer
  );
  const user = useSelector((store: RootState) => store.user);

  const [toggle, setToggle] = React.useState("left");
  const [all, setAll] = useState(true);
  const [friends, setFriends] = useState(false);
  const [family, setFamily] = useState(false);
  const [requests, setRequests] = useState(false);
  const [numberOfRequests, setNumberOfRequests] = useState(0);

  const handleToggle = (event: unknown, newToggle: string) => {
    setToggle(newToggle);
  };

  const handleAll = () => {
    setAll(true);
    setFriends(false);
    setFamily(false);
    setRequests(false);
  };

  const handleFriends = () => {
    setAll(false);
    setFriends(true);
    setFamily(false);
    setRequests(false);
  };

  const handleFamily = () => {
    setAll(false);
    setFriends(false);
    setFamily(true);
    setRequests(false);
  };

  const handleRequests = () => {
    setAll(false);
    setFriends(false);
    setFamily(false);
    setRequests(true);
  };

  const handleRemove = (id) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to remove this connection?",
      text: "The removal of this friend will be permanent.",
      showCancelButton: true,
      customClass: "remove-connection",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch({
          type: "DELETE_CONNECTIONS",
          payload: { connectionId: id, userId: user.user?.id },
        });
      }
    });
  };

  const handleAccept = (id) => {
    dispatch({
      type: "ACCEPT_CONNECTIONS",
      payload: { connectionId: id, userId: user.user?.id },
    });
  };

  useEffect(() => {
    if (!user) return;
    dispatch({ type: "GET_CONNECTIONS", payload: user.user?.id });
  }, [user]);

  useEffect(() => {
    let temporaryNumberOfRequests = 0;

    for (const connection of connections) {
      if (connection.pending == true && user.user?.id == connection.user_B_id) {
        temporaryNumberOfRequests++;
      }
    }
    setNumberOfRequests(temporaryNumberOfRequests);
  }, [connections]);
  console.log("render connections", connections);
  return (
    <>
      <Box className="connections__block">
        <div className="connections__tabs">
          <ToggleButtonGroup
            value={toggle}
            color="primary"
            size="small"
            exclusive
            onChange={(event) => {
              handleToggle(null, (event.target as any).value);
            }}
            aria-label="connections"
            className="connections__tab-block"
          >
            <ToggleButton
              onClick={() => {
                handleAll();
              }}
              value="All"
              aria-label="All"
              className="connections__tab-item"
            >
              All
            </ToggleButton>

            <ToggleButton
              onClick={() => {
                handleFriends();
              }}
              value="friends"
              aria-label="friends"
              className="connections__tab-item"
            >
              Friends
            </ToggleButton>

            <ToggleButton
              onClick={() => {
                handleFamily();
              }}
              value="family"
              aria-label="family"
              className="connections__tab-item"
            >
              Family
            </ToggleButton>

            <ToggleButton
              onClick={() => {
                handleRequests();
              }}
              value="requests"
              aria-label="requests"
              className="connections__tab-item"
            >
              <Stack spacing={2} direction="row">
                <Badge
                  badgeContent={numberOfRequests}
                  className="request__count"
                >
                  Requests
                </Badge>
              </Stack>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <Box className="connections__list">
          {all &&
            connections?.map(
              (connect, i) =>
                (connect.relationship == "friend" || "family") &&
                connect.pending == false && (
                  <Paper className="connections__list-item" elevation={3}>
                    <div className="connections__image">
                      <img
                        className="connectionsImage"
                        src={connect.profile_image}
                      />
                    </div>
                    <div className="connections__details">
                      {connect.first_name + " " + connect.last_name}
                      <div className="connections__details-location">
                        {connect.state}, {connect.country}
                      </div>
                      <div className="connections__details-relation">
                        as {connect.relationship}
                      </div>
                    </div>
                    <Button
                      cssClass="connectionsRemove"
                      onClick={() => handleRemove(connect.connection_id)}
                      label="Remove"
                      primary={true}
                    />
                  </Paper>
                )
            )}
          {friends &&
            connections?.map(
              (connect, i) =>
                connect.relationship == "friend" &&
                connect.pending == false && (
                  <Paper className="connections__list-item" elevation={3}>
                    <div className="connections__image">
                      <img
                        className="connectionsImage"
                        src={connect.profile_image}
                      />
                    </div>
                    <div className="connections__details">
                      {connect.first_name + " " + connect.last_name}
                      <div className="connections__details-location">
                        {connect.state}, {connect.country}
                      </div>
                      <div className="connections__details-relation">
                        as {connect.relationship}
                      </div>
                    </div>
                    <Button
                      cssClass="connectionsRemove"
                      onClick={() => handleRemove(connect.connection_id)}
                      label="Remove"
                      primary={true}
                    />
                  </Paper>
                )
            )}
          {family &&
            connections?.map(
              (connect, i) =>
                connect.relationship == "family" &&
                connect.pending == false && (
                  <Paper className="connections__list-item" elevation={3}>
                    <div className="connections__image">
                      <img
                        className="connectionsImage"
                        src={connect.profile_image}
                      />
                    </div>
                    <div className="connections__details">
                      {connect.first_name + " " + connect.last_name}
                      <div className="connections__details-location">
                        {connect.state}, {connect.country}
                      </div>
                      <div className="connections__details-relation">
                        as {connect.relationship}
                      </div>
                    </div>
                    <Button
                      cssClass="connectionsRemove"
                      onClick={() => handleRemove(connect.connection_id)}
                      label="Remove"
                      primary={true}
                    />
                  </Paper>
                )
            )}
          {requests &&
            connections?.map(
              (connect, i) =>
                connect?.pending == true &&
                user.user?.id == connect?.user_B_id && (
                  <Paper className="connections__list-item" elevation={3}>
                    <div className="connections__image">
                      <img
                        src={connect.profile_image}
                        className="connectionsImage"
                      />
                    </div>
                    <div className="connections__details">
                      {connect.first_name + " " + connect.last_name}
                      <div className="connections__details-location">
                        {connect.state}, {connect.country}
                      </div>
                      <div className="connections__details-relation">
                        as {connect.relationship}
                      </div>
                    </div>
                    <div className="requestBtn">
                      <Button
                        cssClass="connectionsAccept"
                        onClick={() => handleAccept(connect.connection_id)}
                        label="Confirm"
                        primary={true}
                      />
                      <Button
                        cssClass="connectionsRemove"
                        onClick={() => handleRemove(connect.connection_id)}
                        label="Remove"
                      />
                    </div>
                  </Paper>
                )
            )}
        </Box>
      </Box>
    </>
  );
}

export default Connections;

import React from "react";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/_root.reducer";
import LoginRegistrationContainer from "../LoginRegistrationCotainer/LoginRegistrationContainer";
function ProtectedRoute({ children, ...props }) {
  const user = useSelector((store: RootState) => store.user);
  const ProtectedComponent = () => children;
  return (
    <Route {...props}>
      {user.user?.id ? (
        <ProtectedComponent />
      ) : (
        <LoginRegistrationContainer page="login" />
      )}
    </Route>
  );
}

export default ProtectedRoute;

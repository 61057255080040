import { Link } from "@mui/material";
import React from "react";
import "./Footer.css";
import { useParams } from "react-router-dom";
function Footer() {
  const params = useParams<{ view: string; userInParams: string }>();
  const view = params.view;
  return (
    <footer className="footer">
      <div className="footer-links">
        <Link href="" className="footer-link">Term &amp; Conditions</Link>
        <Link href="" className="footer-link">Privacy Policy</Link>
      </div>
      <div className={`${(view !== "login" && view !== "registration") && 'main-footer'} footer-copyright`}>
        All Copyright reserved © Library of Life
      </div>
    </footer>
  )
}

export default Footer;

import React, { FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { WebAuth } from "auth0-js";
import Box from "@mui/material/Box";
import { IconButton, InputAdornment, Link } from "@mui/material";
import { RootState } from "../../redux/reducers/_root.reducer";
import { PageTitle } from "../../stories/PageTitle";
import { InputField } from "../../stories/InputField";
import { Button } from "../../stories/Button";
import { Select } from "../../stories/Select";
import { US_STATES } from "../../constants";
import "./Register.css";
import PasswordShow from "@mui/icons-material/Visibility";
import PasswordHide from "@mui/icons-material/VisibilityOff";
import { useValidation } from "../../hooks/useValidation";

function RegisterForm() {
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [password, setPassword] = useState("");
  const [hidePassword, setHidePassword] = useState(true);
  const [birthday, setBirthday] = useState("");

  const errors = useSelector((store: RootState) => store.errors);
  const dispatch = useDispatch();
  const history = useHistory();
  const { validateEmail } = useValidation();
  const location = useLocation();

  const webAuth = new WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN ?? "",
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID ?? "",
    redirectUri: `${window.location.origin}/landingpage${location.search}`,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    responseType: "token",
  });

  const registerUser = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    webAuth.signup(
      {
        email: username,
        password,
        connection: "Username-Password-Authentication",
      },
      (err, res) => {
        if (err) {
          const signupError =
            err?.original?.response?.body?.message ||
            err?.original?.response?.body?.description ||
            err?.original?.response?.body?.error;

          dispatch({
            type: "REGISTRATION_ERROR",
            payload: {
              code: err.code,
              message: signupError,
            },
          });
        } else {
          dispatch({
            type: "REGISTER",
            payload: {
              firstName: firstName,
              lastName: lastName,
              city: city,
              state: state,
              country: country,
              auth0Id: `auth0|${res.Id}`,
              birthday: birthday,
              email: username,
              password,
            },
          });
        }
      }
    );
  };

  return (
    <Box
      className="registrationFormBox"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <form className="formPanel" onSubmit={registerUser}>
        <PageTitle pageTitle={true} label="Register" />
        <p className="login-form__details">
          Create an account by filling the bottom details.
        </p>
        <Box className="input-column2">
          <InputField
            placeholder="Enter First Name"
            type="text"
            name="firstName"
            inputLabel="First Name"
            required={true}
            value={firstName}
            InputProps={{
              onChange: (event) => {
                setFirstName(event.target.value);
              },
            }}
          />
          <InputField
            placeholder="Enter Last Name"
            type="text"
            name="lastName"
            inputLabel="Last Name"
            required={true}
            value={lastName}
            InputProps={{
              onChange: (event) => {
                setLastName(event.target.value);
              },
            }}
          />
        </Box>
        <InputField
          placeholder="Enter Email Address"
          type="email"
          name="username"
          inputLabel="Email Address"
          required={true}
          value={username}
          error={!validateEmail(username)}
          helperText={
            !validateEmail(username)
              ? "Please enter a valid email address"
              : null
          }
          InputProps={{
            onChange: (event) => {
              setUsername(event.target.value);
            },
          }}
        />
        <Box className="input-column2">
          <InputField
            placeholder="Enter City"
            type="text"
            name="city"
            inputLabel="City"
            required={true}
            value={city}
            InputProps={{ onChange: (event) => setCity(event.target.value) }}
          />
          <Select
            inputLabel="State"
            options={US_STATES}
            value={state}
            onChange={(event) => setState(event.target.value)}
          />
        </Box>
        <Box className="input-column2">
          <InputField
            placeholder="Enter Country"
            type="text"
            name="country"
            inputLabel="Country"
            value={country}
            required
            InputProps={{ onChange: (event) => setCountry(event.target.value) }}
          />
          <InputField
            placeholder="Birthday"
            type="date"
            name="birthday"
            inputLabel="Birthday"
            value={birthday}
            required
            inputProps={{ max: new Date().toISOString().split("T")[0] }}
            InputProps={{
              onChange: (event) => setBirthday(event.target.value),
            }}
          />
        </Box>
        <Box>
          <InputField
            placeholder="Enter Password"
            type={hidePassword ? "password" : "text"}
            name="password"
            inputLabel="Password"
            value={password}
            required
            InputProps={{
              onChange: (event) => setPassword(event.target.value),
              endAdornment: (
                <InputAdornment position="end" sx={{ width: "0px" }}>
                  <IconButton onClick={() => setHidePassword(!hidePassword)}>
                    {hidePassword ? <PasswordShow /> : <PasswordHide />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errors.registrationMessage && (
            <h3 className="alert" role="alert">
              {errors.registrationMessage}
            </h3>
          )}
        </Box>
        <div className="centerRegister">
          <Button
            primary={true}
            type="submit"
            label="SIGN UP"
            cssClass="register-button"
          />
          <div className="mobile-register">
            Don't have an account?{" "}
            <Link
              onClick={() => {
                history.push("/login");
              }}
            >
              Login
            </Link>
          </div>
        </div>
      </form>
    </Box>
  );
}

export default RegisterForm;

import React from "react";
import aboutImage from './aboutus.png';
import './AboutPage.css';

function AboutPage() {
  return (
    <div className="aboutus">
      <h1 className="aboutus__title">About Us</h1>
        <p className="aboutus__details">
          It is the wealth of emotions, the moments of love and affections
          cherished over a lifetime and the sharing of knowledge that becomes an
          invaluable asset of one’s life that is passed over from generation to
          generation. What if you can preserve these moments forever? With a
          desire to answer the above question, we worked toward this unique
          concept that we came to call-Library of Life. We help preserve your
          emotions, messages, and more. This service is jointly promoted by
          Freddy Hutt, an enterprising innovator, and Ember that is the
          technology/solutions partner for putting his dreams into action.
          Leveraging the latest platforms and technologies combined with a
          humanistic passion as envisioned by our promoter, you can relive your
          memories, share emotions, and of course remain cherished forever in
          the hearts of your loved ones. Let’s make a difference in how we view
          our relationships in this materialistic world and showcase the power
          of emotions - an eternal wealth that shall last forever.
        </p>
        <div className="aboutus__image">
          <img src={aboutImage} className="aboutus__image-src" alt="about us"/>
        </div>
    </div>
  );
}

export default AboutPage;

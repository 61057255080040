import React from "react";

const LoginBanner = () => {
  return (
    <div className="login-banner">
      <img src="/images/banners/login-image.png" alt="Library of Life Banner" />
    </div>
  );
};
export default LoginBanner;

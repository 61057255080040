import React from 'react';
import './button.css';

interface ButtonProps {
  primary?: boolean;
  type?: string;
  label?: string;
  cssClass?: string,
  icon?: string,
  iconPos?: string,
  onClick?: any;
}
export const Button = ({
  primary = false,
  type = "submit",
  label,
  cssClass,
  onClick,
  icon,
  iconPos,
  ...props
}: ButtonProps) => {
  const mode = primary ? 'lol-button--primary' : 'lol-button--secondary';
  return (
    <button
      type={type === 'submit'? 'submit': 'button'}
      className={['lol-button', mode, cssClass].join(' ')}
      onClick={onClick}
      {...props}
    >
      {(icon && iconPos === 'left') && <span className='lol-icon'>{icon}</span>} {label} {(icon && iconPos === 'right') && <span className='lol-icon right'>{icon}</span>}
    </button>
  );
};

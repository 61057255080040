import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import "./EditProfile.css";
import { RootState } from "../../redux/reducers/_root.reducer";
import { PageTitle } from "../../stories/PageTitle";
import { Button } from "../../stories/Button";
import { InputField } from "../../stories/InputField";
import { useHistory } from "react-router-dom";
import { US_STATES } from "../../constants";
import { Select } from "../../stories/Select";

function EditProfile() {
  const history = useHistory();
  const errors = useSelector((store: RootState) => store.errors);
  const user = useSelector((store: RootState) => store.user);
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState(user.user?.first_name);
  const [lastName, setLastName] = useState(user.user?.last_name);
  const [city, setCity] = useState(user.user?.city);
  const [state, setState] = useState(user.user?.state);
  const [country, setCountry] = useState(user.user?.country);
  const [birthday, setBirthday] = useState(
    new Date(user.user?.birthday ?? 1).toISOString().split("T")[0].toString()
  );

  const updateUser = (event) => {
    event.preventDefault();

    dispatch({
      type: "UPDATE_USER",
      payload: {
        firstName: firstName,
        lastName: lastName,
        city: city,
        state: state,
        country: country,
        id: user.user?.id,
        birthday: birthday,
      },
    });
    history.push(`/user/${user.user?.id}/videos`);
  };

  return (
    <Box alignItems="center" justifyContent="center" className="edit-profile">
      <form className="formPanel" onSubmit={updateUser}>
        <PageTitle pageTitle={true} label="Edit Information" />
        {errors.registrationMessage && (
          <h3 className="alert" role="alert">
            {errors.registrationMessage}
          </h3>
        )}

        <InputField
          placeholder="Enter First Name"
          type="text"
          name="firstName"
          inputLabel="First Name"
          required={true}
          value={firstName}
          InputProps={{
            onChange: (event) => {
              setFirstName(event.target.value);
            },
          }}
        />
        <InputField
          placeholder="Enter Last Name"
          type="text"
          name="lastName"
          inputLabel="Last Name"
          required={true}
          value={lastName}
          InputProps={{
            onChange: (event) => {
              setLastName(event.target.value);
            },
          }}
        />
        <InputField
          placeholder="Enter City"
          type="text"
          name="city"
          inputLabel="City"
          required={true}
          value={city}
          InputProps={{
            onChange: (event) => {
              setCity(event.target.value);
            },
          }}
        />
        <Select
          inputLabel="State"
          options={US_STATES}
          value={state}
          onChange={(event) => setState(event.target.value)}
        />

        <InputField
          placeholder="Enter Country"
          type="text"
          name="country"
          inputLabel="Country"
          required={true}
          value={country}
          InputProps={{
            onChange: (event) => {
              setCountry(event.target.value);
            },
          }}
        />

        <InputField
          placeholder="Birthday"
          type="date"
          name="birthday"
          inputLabel="Birthday"
          value={birthday}
          required
          inputProps={{ max: new Date().toISOString().split("T")[0] }}
          InputProps={{
            onChange: (event) => setBirthday(event.target.value),
          }}
        />

        <div className="centerRegister">
          <Button
            primary={true}
            type="submit"
            label="Save"
            cssClass="save-button"
          />
        </div>
      </form>
    </Box>
  );
}

export default EditProfile;

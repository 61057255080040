import React from "react";
import { useDispatch } from "react-redux";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function PermissionDropdown({ video }) {
  const dispatch = useDispatch();
  const handleChange = (event) => {
    dispatch({
      type: "UPDATE_PERMISSION",
      payload: { video_id: video.id, permission: event.target.value },
    });
  };

  return (
    <>
      <FormControl className="share-list" size="small">
        <InputLabel id="permission-select-small">Who can see this?</InputLabel>

        <Select
          labelId="permission-select-small"
          id="permission-select-small"
          value={video.permission}
          label="Who can see?"
          onChange={handleChange}
          className="video-share-select"
        >
          <MenuItem value={"everyone"}>Everyone</MenuItem>
          <MenuItem value={"friend"}>Friends</MenuItem>
          <MenuItem value={"family"}>Family</MenuItem>
          <MenuItem value={"invite-only"}>Invite-Only</MenuItem>
        </Select>
      </FormControl>
    </>
  );
}

export default PermissionDropdown;

import React from "react";
import { useDispatch } from "react-redux";

function LogOutButton() {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
  };

  return (
    <span
      onClick={handleLogout}
    >
      Log Out
    </span>
  );
}

export default LogOutButton;

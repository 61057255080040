import { Prompt } from "../../../models/prompt";

const promptReducer = (
  state: Prompt[] = [],
  action: { type: "SET_PROMPTS" | "CLEAR_PROMPTS"; payload: Prompt[] }
) => {
  switch (action.type) {
    case "SET_PROMPTS":
      return action.payload;
    case "CLEAR_PROMPTS":
      return [];
    default:
      return state;
  }
};
export default promptReducer;

import { put, takeLatest } from "redux-saga/effects";
import axios from "axios";
function* getConnections(action) {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = yield axios.get(`/api/connections/${action.payload}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    yield put({ type: "SET_CONNECTIONS", payload: response.data });
  } catch (error) {
    console.log("Error in get connections saga", error);
  }
}

function* postRequest(action) {
  const accessToken = localStorage.getItem("accessToken");

  try {
    yield axios.post("/api/connections", action.payload, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  } catch (error) {
    console.log("Error with request saga:", error);
  }
}

function* acceptConnections(action) {
  const { connectionId: id, userId } = action.payload;
  try {
    yield axios.put(`/api/connections/${id}`);
    yield put({ type: "GET_CONNECTIONS", payload: userId });
  } catch (error) {
    console.log(error);
  }
}

function* deleteConnections(action) {
  const id = action.payload.connectionId;
  const userId = action.payload.userId;
  const accessToken = localStorage.getItem("accessToken");

  try {
    yield axios.delete(`/api/connections/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    yield put({ type: "GET_CONNECTIONS", payload: userId });
  } catch (error) {
    console.log(error);
  }
}
function* connectionsSaga() {
  yield takeLatest("GET_CONNECTIONS", getConnections);
  yield takeLatest("POST_REQUEST", postRequest);
  yield takeLatest("ACCEPT_CONNECTIONS", acceptConnections);
  yield takeLatest("DELETE_CONNECTIONS", deleteConnections);
}
export default connectionsSaga;

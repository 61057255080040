import { InputLabel, TextField, TextFieldProps } from "@mui/material";
import React, { useState } from "react";
import "./InputField.css";

export const InputField = ({
  inputLabel,
  backgroundColor,
  ...props
}: TextFieldProps & { inputLabel?: string; backgroundColor?: string }) => {
  let emptyField = props.value !== "" ? "active" : "";
  const [isTouched, setIsTouched] = useState(false);
  return (
    <div className="input-control">
      {inputLabel && (
        <InputLabel className="lol-label">{inputLabel}</InputLabel>
      )}
      <TextField
        className={[
          "lol-input",
          `lol-input--${props.type}`,
          emptyField,
          `lol-input--bg${backgroundColor}`,
        ].join(" ")}
        variant="outlined"
        onBlur={() => setIsTouched(true)}
        {...props}
        error={isTouched && props.error}
        helperText={isTouched && props.helperText}
      />
    </div>
  );
};

import { put, takeLatest } from "redux-saga/effects";
import axios from "axios";

function* getMyVideos() {
  const accessToken = localStorage.getItem("accessToken");
  try {
    const videos = yield axios.get("/api/video/userVideos", {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    yield put({ type: "SET_USER_VIDEOS", payload: videos.data });
  } catch (error) {
    console.log("video saga GET", error);
  }
}
function* getVideoById() {
  const id = 10;
  try {
    const response = yield axios.get(`/api/video/${id}`);
    yield put({ type: "SET_SINGLE_VIDEO", payload: response.data });
  } catch (error) {
    console.log("video saga GET", error);
  }
}

function* videoSaga() {
  yield takeLatest("GET_MY_VIDEOS", getMyVideos);
  yield takeLatest("GET_VIDEO_BY_ID", getVideoById);
}

export default videoSaga;

import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
function* postBanner(action) {    
    try {
        yield axios.put('/api/banner/', action.payload);
        yield put ({type: 'SET_BANNER_REDUCER', payload: action.payload });
        yield put ({ type: 'GET_USER' });
    } catch (err) {
        console.log(err);
    }
}
function* bannerSaga() {
    yield takeLatest('POST_BANNER', postBanner);
}
export default bannerSaga;
import "./UserVideoItem.css";
import React from "react";
import { useSelector } from "react-redux";
import ReactPlayerComponent from "../_Widgets/ReactPlayerComponent";
import PermissionDropdown from "../_Widgets/PermissionDropdown";
import DeleteButton from "../_Widgets/DeleteButton";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import ShareDialogBox from "../_Widgets/ShareDialogBox";
import { Video } from "../../../models/video";
import { Grid } from "@mui/material";

function UserVideoItem({
  video,
  shareMessage,
  onDelete,
  shareButtonVisible,
  permissionsVisible,
  autoPlay,
}: {
  video: Video;
  shareMessage?: string;
  onDelete?: () => void;
  shareButtonVisible?: boolean;
  permissionsVisible?: boolean;
  autoPlay?: boolean;
}) {
  return (
    <>
      <Grid item xs={12} md={4} key={video.id} className="video-listing">
        <Container>
          <Card sx={{ minHeight: "18.5em" }} className="video-items">
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              className="video-title"
            >
              {video.prompt}
            </Typography>

            <ReactPlayerComponent videoURL={video.url} autoPlay={autoPlay} />
            <>
              <CardActions className="card-action">
                {permissionsVisible && <PermissionDropdown video={video} />}

                <div className="card-view">
                  {onDelete && <DeleteButton onDelete={onDelete} />}
                  {shareButtonVisible && (
                    <ShareDialogBox
                      aria-labelledby="confirm-dialog"
                      video={video}
                    />
                  )}
                </div>
              </CardActions>
            </>
            <div className="share-message">{shareMessage}</div>
          </Card>
        </Container>
      </Grid>
    </>
  );
}

export default UserVideoItem;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone, { IFileWithMeta } from "react-dropzone-uploader";
import Swal from "sweetalert2";
import { Modal, Typography, Box } from "@mui/material";
import { RootState } from "../../redux/reducers/_root.reducer";
import { Button } from "../../stories/Button";
import { VscEdit } from "react-icons/vsc";
import "./PhotoUploader.css";
import axios from "axios";

function PhotoUploader() {
  useEffect(() => {
    dispatch({
      type: "CLEAR_PHOTO",
    });
  }, []);

  const dispatch = useDispatch();
  const photo = useSelector((store: RootState) => store.photoReducer);
  const [openPhotoModal, setOpenPhotoModal] = useState(false);
  const user = useSelector((store: RootState) => store.user);

  const handleSubmit = async (
    files: IFileWithMeta[],
    allFiles: IFileWithMeta[]
  ) => {
    const f = files[0];

    const formData = new FormData();
    formData.append("photo", f.file.slice());
    await axios.put(`/api/photo/${user.user?.id}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    allFiles.forEach((f) => f.remove());
    setOpenPhotoModal(false);
    setTimeout(swalWait, 2000);
    function swalWait() {
      Swal.fire({
        icon: "success",
        title: "Successful Upload",
        footer: "Photo has been updated successfully",
      });
      dispatch({ type: "GET_USER" });
    }
  };

  const handleChangePhoto = () => {
    dispatch({
      type: "CLEAR_PHOTO",
    });
  };

  const handleOpenPhotoModal = () => {
    setOpenPhotoModal(true);
  };

  const handleClosePhotoModal = () => {
    setOpenPhotoModal(false);
  };

  return (
    <div className="upload">
      {photo?.file ? (
        <Box>
          <p>{photo.file.name}</p>
          <Button onClick={handleChangePhoto} label="Remove Photo" />
        </Box>
      ) : (
        <Button
          onClick={handleOpenPhotoModal}
          primary={true}
          label={<VscEdit />}
          cssClass="edit-button"
        />
      )}
      <Modal
        open={openPhotoModal}
        onClose={handleClosePhotoModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="photo-upload__block">
          <Typography
            className="model-title"
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Upload your Image
          </Typography>
          {photo?.file ? (
            <h1>{photo?.file.name} Has Been Added!</h1>
          ) : (
            <Dropzone
              onSubmit={handleSubmit}
              maxFiles={1}
              canCancel={false}
              inputContent={(files, extra) =>
                extra.reject ? "Photo files only" : "Click or Drag 1 Photo Here"
              }
              accept="image/*"
            ></Dropzone>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default PhotoUploader;

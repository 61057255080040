import "./Nav.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import AutocompleteSearch from "../_Widgets/AutocompleteSearch";
import { Menu } from "@mui/material";
import { RootState } from "../../redux/reducers/_root.reducer";
import { Button } from "../../stories/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Uploader from "../VideoUploadPage/Uploader";
import searchIcon from "../_Widgets/search-icon.png";
import { styled, alpha } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Menuitem from "./Menuitem";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";

interface pageProp {
  forgotPasswordCheck: boolean;
  setForgotPasswordCheck?: (
    value: boolean | ((prevVar: boolean) => boolean)
  ) => void;
}
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
const Nav: React.FC<pageProp> = (props: pageProp) => {
  const user = useSelector((store: RootState) => store.user);
  const listOfUsers = useSelector(
    (store: RootState) => store.searchReducer.listOfUsers
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [openMenuNew, setOpen] = React.useState(false);
  const [openSearch, setOpenSearch] = React.useState(false);

  const handleDrawerSearchOpen = () => {
    setOpenSearch(true);
  };

  const handleDrawerSearchClose = () => {
    setOpenSearch(false);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!user.user?.id) return;
    dispatch({ type: "GET_USERS", payload: user.user?.id });
  }, [user]);

  return (
    <div className="nav">
      <div className="header-left">
        {props.forgotPasswordCheck && (
          <a
            className="forgot-password--link"
            onClick={() => props?.setForgotPasswordCheck(false)}
          >
            <ArrowBackIosOutlinedIcon color="warning" sx={{ width: 20 }} /> Back
            to Login
          </a>
        )}
        <div id="logo-link">
          <Link to="/">
            <img id="logo" src="/images/assets/logo.jpg" />
          </Link>
        </div>
        {!user.user?.id && (
          <>
            {!props.forgotPasswordCheck && (
              <div className="navLinks">
                {location.pathname === "/login" ? (
                  <Button
                    label="SIGN UP"
                    cssClass="header-signup"
                    onClick={() => {
                      history.push(`/registration${location.search}`);
                    }}
                  />
                ) : (
                  <Button
                    label="LOGIN"
                    cssClass="header-login"
                    onClick={() => {
                      history.push("/login");
                    }}
                  />
                )}
              </div>
            )}
          </>
        )}
        {user.user?.id && (
          <div id="search" className="desktop-search">
            <AutocompleteSearch listOfUsers={listOfUsers} />
          </div>
        )}
      </div>
      {user.user?.id && (
        <div className="navLinks">
          <>
            <div className="mobile-search">
              {(["top"] as const).map((anchorSearch) => (
                <React.Fragment key={anchorSearch}>
                  <img
                    onClick={handleDrawerSearchOpen}
                    className="search-icon"
                    src={searchIcon}
                    width="16px"
                  />
                  <Drawer
                    anchor={anchorSearch}
                    open={openSearch}
                    className="menu-drawer"
                    onClose={handleDrawerSearchClose}
                  >
                    <AutocompleteSearch listOfUsers={listOfUsers} />
                  </Drawer>
                </React.Fragment>
              ))}
            </div>
            <div className="upload-video-header">
              <Uploader videoLabel="Upload Video" />
            </div>
            <div className="desktop-menu">
              <Button
                onClick={handleClick}
                label={<MoreVertIcon />}
                cssClass="menu-header"
              />
            </div>
            <div className="mobile-menu">
              {(["right"] as const).map((anchor) => (
                <React.Fragment key={anchor}>
                  <MoreVertIcon onClick={handleDrawerOpen} />
                  <Drawer
                    anchor={anchor}
                    open={openMenuNew}
                    className="menu-drawer"
                    onClose={handleDrawerClose}
                  >
                    <span className="menu-close" onClick={handleDrawerClose}>
                      <CloseIcon />
                    </span>
                    <Menuitem handleClose={handleClose} user={user} />
                  </Drawer>
                </React.Fragment>
              ))}
            </div>
          </>
          <div id="menu">
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <Menuitem handleClose={handleClose} user={user} />
            </StyledMenu>
          </div>
        </div>
      )}
    </div>
  );
};

export default Nav;

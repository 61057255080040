import React from "react";
import ReactPlayer from "react-player";

interface ReactPlayerComponentProps {
  videoURL: string;
  autoPlay?: boolean;
}

function ReactPlayerComponent({
  videoURL,
  autoPlay,
}: ReactPlayerComponentProps) {
  return (
    <ReactPlayer
      playing={autoPlay}
      className="react-player"
      width="100%"
      height="100%"
      url={videoURL}
      controls={true}
      // Set volume to 0 to mute video and always allow autoplay
      // otherwise, autoplay may not always work
      volume={0}
    />
  );
}

export default ReactPlayerComponent;

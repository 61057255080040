import React from "react";
import BannerItem from "./BannerItem";
import "../../UserPage/UserPage.css";

import {
  Button as MuButton,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  RadioGroup,
  FormControl,
} from "@mui/material";
import { VscEdit } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers/_root.reducer";

import "./BannerDialog.css";
import { Button } from "../../../stories/Button";

const banners = [
  { name: "Flowers", url: "/images/banners/yellowwall.png" },
  { name: "Tree", url: "/images/banners/treeoflife.png" },
  { name: "Clouds", url: "/images/banners/clouds.png" },
  { name: "Balloons", url: "/images/banners/hotairballoons.png" },
  { name: "Ripple", url: "/images/banners/ripple.png" },
  { name: "Lemons", url: "/images/banners/lemons.png" },
  { name: "River", url: "/images/banners/mountainriver.png" },
  { name: "Canoe", url: "/images/banners/canoe.png" },
  { name: "Canyon", url: "/images/banners/canyon.png" },
  { name: "Mountains", url: "/images/banners/mountainsunset.png" },
  { name: "Rainbow", url: "/images/banners/rainbowsmoke.png" },
  { name: "Yarn", url: "/images/banners/yarn.png" },
  { name: "Pier", url: "/images/banners/pier.png" },
  { name: "Paint", url: "/images/banners/abstractpaint.png" },
  { name: "Blossoms", url: "/images/banners/cherryblossoms.png" },
  { name: "Citrus", url: "/images/banners/citrus.png" },
  { name: "Droplet", url: "/images/banners/droplet.png" },
  { name: "Grass", url: "/images/banners/frostedgrass.png" },
  { name: "Lavender", url: "/images/banners/lavender.png" },
  { name: "Sunflower", url: "/images/banners/sunflower.png" },
];

function BannerDialog() {
  const selectedBanner = useSelector((store: RootState) => store.bannerReducer);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const user = useSelector((store: RootState) => store.user);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    dispatch({
      type: "POST_BANNER",
      payload: {
        banner: selectedBanner,
        userId: user.user?.id,
      },
    });
    setOpen(false);
  };

  return (
    <>
      <MuButton
        onClick={handleClickOpen}
        id="bannerButton"
        color="primary"
        size="small"
        variant="contained"
        startIcon={<VscEdit />}
      ></MuButton>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        disableScrollLock={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="banner-header">Edit Banner</div>
        <DialogContent className="banner-modal-container">
          <Grid container spacing={1}>
            <FormControl>
              <RadioGroup className="banner-item">
                {banners?.map((banner, i) => {
                  return (
                    //loops thru array of banners to create each banner item
                    <Grid item key={banner.name}>
                      <BannerItem
                        key={i}
                        banner={banner}
                        selectedBanner={selectedBanner}
                      />
                    </Grid>
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            label="Cancel"
            cssClass="banner-button"
            type="button"
            onClick={handleClose}
          />
          <Button
            label="Save Changes"
            cssClass="banner-button"
            primary={true}
            onClick={handleSubmit}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default BannerDialog;

import { Photo } from "../../../models/photo";

const photoReducer = (
  state: Photo = null,
  action: { type: "SET_USER_PHOTO" | "CLEAR_PHOTO"; payload: Photo }
) => {
  switch (action.type) {
    case "SET_USER_PHOTO":
      return action.payload;
    case "CLEAR_PHOTO":
      return null;
    default:
      return state;
  }
};
export default photoReducer;

import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
function* getPromptList() {
  try {
    const prompts = yield axios.get('/api/prompt');
    yield put({ type: 'SET_PROMPTS', payload: prompts.data });
  } catch (error) {
    console.log('prompt saga GET', error);
  }
}

function* getPrompt() {
  yield takeLatest('GET_PROMPTS', getPromptList);
}

export default getPrompt;
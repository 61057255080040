import React, { useEffect, useState } from "react";
import LoginForm from "../LoginForm/LoginForm";
import { useHistory } from "react-router-dom";
import Nav from "../Nav/Nav";
import LoginBanner from "../LoginBanner/LoginBanner";
import Footer from "../Footer/Footer";
import RegisterForm from "../RegisterForm/RegisterForm";
import "./LoginRegistrationContainer.css";
import ForgotPassword from "../ForgotPassword/ForgotPassword";

interface pageProp {
  page: string;
}

const LoginRegistrationContainer: React.FC<pageProp> = (props: pageProp) => {
  const [forgotPasswordCheck, setForgotPasswordCheck] =
    useState<boolean>(false);

  return (
    <div className="container">
      <LoginBanner />
      <div className="login-form">
        <Nav
          forgotPasswordCheck={forgotPasswordCheck}
          setForgotPasswordCheck={setForgotPasswordCheck}
        />
        {props.page === "login" ? (
          <>
            {forgotPasswordCheck ? (
              <ForgotPassword setForgotPasswordCheck={setForgotPasswordCheck} />
            ) : (
              <LoginForm setForgotPasswordCheck={setForgotPasswordCheck} />
            )}
          </>
        ) : (
          <RegisterForm />
        )}
        <Footer />
      </div>
    </div>
  );
};

export default LoginRegistrationContainer;

import { combineReducers } from "redux";

const registrationErrors = {
  ["invalid_password"]:
    "Password is too weak. Your password must: * At least 8 characters in length * Contain at least 3 of the following 4 types of characters: * lower case letters (a-z) * upper case letters (A-Z) * numbers (i.e. 0-9) * special characters (e.g. !@#$%^&*). Try again!",
  ["invalid_signup"]:
    "Oops! That didn't work. The username might already be taken. Try again!",
};

const loginMessage = (
  state = "",
  action: { type: string; payload: string }
) => {
  switch (action.type) {
    case "CLEAR_LOGIN_ERROR":
      return "";
    case "LOGIN_INPUT_ERROR":
      return "Enter your username and password!";
    case "LOGIN_FAILED":
      return `${action.payload ?? "Oops! Something went wrong!"}. Try again!`;
    case "LOGIN_FAILED_NO_CODE":
      return "Oops! Something went wrong! Is the server running?";
    default:
      return state;
  }
};

// TODO: Oportunity for improvement here. Handle errors like REGISTRATION_ERROR
const registrationMessage = (
  state = "",
  action: { type: string; payload: { code: string; message: string } }
) => {
  switch (action.type) {
    case "CLEAR_REGISTRATION_ERROR":
      return "";
    case "REGISTRATION_INPUT_ERROR":
      return "Choose a username and password!";
    case "REGISTRATION_FAILED":
      return "Oops! That didn't work. The username might already be taken. Try again!";
    case "REGISTRATION_ERROR":
      return (
        registrationErrors[action.payload.code] ||
        registrationMessage[action.payload.message] ||
        action.payload.message ||
        "Oops! Something went wrong!"
      );
    default:
      return state;
  }
};

export default combineReducers({
  loginMessage,
  registrationMessage,
});

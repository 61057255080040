import { Permission } from "../../models/permission";

const permissionReducer = (
  state: Permission = Permission.UNKNOWN,
  action: { type: "SET_PERMISSION"; payload: Permission }
) => {
  switch (action.type) {
    case "SET_PERMISSION":
      return action.payload;
    default:
      return state;
  }
};
export default permissionReducer;

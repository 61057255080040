import React from "react";
function InfoPage() {
  return (
    <div className="container">
      <p>Info Page</p>
    </div>
  );
}

export default InfoPage;

import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
} from "@mui/material";
import { RootState } from "../../redux/reducers/_root.reducer";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function SelectToShare({ video }) {
  const dispatch = useDispatch();
  const connections = useSelector(
    (store: RootState) => store.connectionsReducer
  );

  const [personName, setPersonName] = React.useState([]);
  const [selectedIDs, setSelectedIDs] = React.useState([]);

  const handleConnectionObj = async (id) => {
    let shareObj = {
      user_id: selectedIDs,
      video_id: video.id,
    };

    let updatedSelectedIDs = [];
    if (selectedIDs.indexOf(id) != -1) {
      updatedSelectedIDs = selectedIDs.filter((idToRemove) => idToRemove != id);
      setSelectedIDs(updatedSelectedIDs);
      shareObj.user_id = updatedSelectedIDs;
      dispatch({ type: "SET_SHARE_REDUCER", payload: shareObj });
    } else {
      setSelectedIDs([...selectedIDs, id]);
      shareObj.user_id = [...selectedIDs, id];
      dispatch({ type: "SET_SHARE_REDUCER", payload: shareObj });
    }
  };

  const handleSelectedName = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <>
      <FormControl className="share-inputfield">
        <InputLabel id="demo-multiple-checkbox-label">
          Choose from your connections
        </InputLabel>

        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={personName}
          onChange={handleSelectedName}
          input={<OutlinedInput label="Choose from your connections " />}
          renderValue={(selected) => selected?.join(", ")}
          MenuProps={MenuProps}
          className="share-dropwdown"
        >
          {connections.map((connection) => (
            <MenuItem
              key={connection.user_id}
              value={connection.first_name + " " + connection.last_name}
            >
              <Checkbox
                onChange={() => handleConnectionObj(connection.user_id)}
                checked={selectedIDs.indexOf(connection.user_id) > -1}
              />

              <ListItemText
                onClick={() => handleConnectionObj(connection.user_id)}
                primary={connection.first_name + " " + connection.last_name}
                secondary={connection.relationship}
              />
            </MenuItem>
          ))}
          {connections.length === 0 && <MenuItem>No Connections</MenuItem>}
        </Select>
      </FormControl>
    </>
  );
}

export default SelectToShare;

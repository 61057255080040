import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import UserVideoItem from "../UserVideoItem/UserVideoItem";

import { Container, Grid } from "@mui/material";
import { RootState } from "../../redux/reducers/_root.reducer";

function ManageLibrary() {
  const dispatch = useDispatch();
  const videos = useSelector((store: RootState) => store.videoReducer);
  const history = useHistory();
  useEffect(() => {
    dispatch({ type: "GET_MY_VIDEOS" });
  }, []);

  return (
    <>
      <Container className="video-container">
        <Grid container className="manage-library-container" spacing={1}>
          <Grid item xs={12}>
            <h1>Manage Library</h1>
          </Grid>
          {videos?.map((video) => {
            return (
              <Grid
                item
                xs={12}
                md={4}
                key={video.id}
                className="video-listing"
              >
                <UserVideoItem video={video} />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </>
  );
}

export default ManageLibrary;

import React from "react";
import MenuItem from "@mui/material/MenuItem";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import LogOutButton from "../_Widgets/LogOutButton";
import { useHistory } from "react-router-dom";

const Menuitem = (props) => {
  const history = useHistory();
  const handleItemClick = (menuLink: string) => {
    history.push(menuLink);
    props.handleClose();
  };
  return (
    <>
      <MenuItem
        onClick={() =>
          handleItemClick(`/user/${props.user.user?.id}/connections`)
        }
      >
        <GroupsOutlinedIcon />
        My Connections
      </MenuItem>
      <MenuItem onClick={() => handleItemClick(`/manageLibrary`)}>
        <LayersOutlinedIcon />
        Manage Library
      </MenuItem>
      <MenuItem onClick={() => handleItemClick("/about")}>
        <InfoOutlinedIcon />
        About Library of Life
      </MenuItem>
      <MenuItem onClick={() => handleItemClick("/#")}>
        <LockResetOutlinedIcon />
        Change Password
      </MenuItem>
      <MenuItem>
        <LogoutOutlinedIcon />
        <LogOutButton />
      </MenuItem>
    </>
  );
};
export default Menuitem;

import { all } from "redux-saga/effects";
import loginSaga from "./login.saga";
import registrationSaga from "./registration.saga";
import userSaga from "./user.saga";
import searchSaga from "./search.saga";
import videoSaga from "./video.saga";
import connectionsSaga from "./connections.saga";
import permissionSaga from "./permission.saga";
import promptSaga from "./prompt.saga";
import deleteSaga from "./delete.saga";
import bannerSaga from "./banner.saga";

export default function* rootSaga() {
  yield all([
    loginSaga(),
    registrationSaga(),
    userSaga(),
    connectionsSaga(),
    searchSaga(),
    videoSaga(),
    promptSaga(),
    permissionSaga(),
    deleteSaga(),
    bannerSaga(),
  ]);
}

import React, { useState } from "react";
import { Button } from "../../stories/Button";

function CopyToClipboard({ url }: { url: string }) {
  const textToCopy = `Here's a video I'd like to share with you: ` + url;

  const [copied, setCopied] = useState(false);

  const copyShortURL = () => {
    navigator.clipboard.writeText(textToCopy);

    setTimeout(() => {
      setCopied(false);
    }, 8000);

    setCopied(true);
  };

  return (
    <>
        <div className="share-dialog__get-link">
          <span className="share-dialog__text">Here's a video I'd like to share with you:</span>
          <h5 className="tiny-share-link">{url}</h5>
        </div>
        {copied ? (
          <div className="share-dialog__link-copied">
            <div className="share-dialog__text">Link copied!</div>
            <div className="share-dialog__text">You can now paste it in an email or text message.</div>
          </div>
        ) : (
          <Button
            primary={true}
            label="Click to Copy Link"
            onClick={copyShortURL}
            cssClass="share-link-button"
          />
        )}
    </>
  );
}

export default CopyToClipboard;

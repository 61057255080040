interface UserState {
  user?: User;
  isLoading: boolean;
}

interface User {
  id: number;
  first_name: string;
  last_name: string;
  city: string;
  state: string;
  country: string;
  about_me: string | null;
  profile_image: string;
  banner_image: string;
  auth0_id: string;
  birthday: Date;
}

const userReducer = (
  state: UserState = { user: undefined, isLoading: true },
  action: {
    payload: User;
    type: "SET_USER" | "UNSET_USER" | "IS_LOADING" | "DONE_LOADING";
  }
): UserState => {
  switch (action.type) {
    case "SET_USER":
      return { user: action.payload, isLoading: false };
    case "UNSET_USER":
      return { user: undefined, isLoading: false };
    case "IS_LOADING":
      return { ...state, isLoading: true };
    case "DONE_LOADING":
      return { ...state, isLoading: false };
    default:
      return state;
  }
};
export default userReducer;

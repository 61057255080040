const bannerReducer = (
  state: string = "",
  action: {
    type: "SET_BANNER_REDUCER" | "CLEAR_BANNER_REDUCER";
    payload: string;
  }
) => {
  switch (action.type) {
    case "SET_BANNER_REDUCER":
      return action.payload;
    case "CLEAR_BANNER_REDUCER":
      return "";
    default:
      return state;
  }
};
export default bannerReducer;

import Uploader from "./Uploader";

function VideoUploadPage() {
  return (
    <main
    >
      <div className="videoWatch">
        <Uploader videoLabel="Add Video" />
      </div>
    </main>
  );
}

export default VideoUploadPage;

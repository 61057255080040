import { Video } from "../../../models/video";

const videoReducer = (state: Video[] = [], action): Video[] => {
  switch (action.type) {
    case "SET_USER_VIDEOS":
      return action.payload;
    case "SET_SEARCHED_USER_VIDEOS":
      return action.payload;
    case "SET_SINGLE_VIDEO":
      return action.payload[0];
    case "CLEAR_VIDEOS":
      return [];
    default:
      return state;
  }
};
export default videoReducer;

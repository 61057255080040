import { put, takeLatest } from "redux-saga/effects";
import axios from "axios";

function* registerUser(action) {
  try {
    yield put({ type: "CLEAR_REGISTRATION_ERROR" });
    yield axios.post("/api/user/register", action.payload);
    yield put({ type: "REGISTRATION_SUCCESS" });
    yield put({
      type: "LOGIN",
      payload: {
        username: action.payload.email,
        password: action.payload.password,
      },
    });
  } catch (error) {
    console.log("registration saga PUT", error);
    yield put({
      type: "REGISTRATION_FAILED",
      payload: { message: error.response.data },
    });
  }
}

function* editUser(action) {
  try {
    yield axios.put(`/api/user/update/${action.payload.id}`, action.payload);
    yield put({ type: "GET_USER" });
  } catch (error) {
    console.log("registration saga PUT", error);
  }
}

function* registrationSaga() {
  yield takeLatest("REGISTER", registerUser);
  yield takeLatest("UPDATE_USER", editUser);
}

export default registrationSaga;

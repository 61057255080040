import { Connection } from "../../../models/connections";

const connectionsReducer = (
  state: Connection[] = [],
  action: {
    type: "SET_CONNECTIONS" | "CLEAR_CONNECTIONS";
    payload: Connection[];
  }
): Connection[] => {
  switch (action.type) {
    case "SET_CONNECTIONS":
      return action.payload;
    case "CLEAR_CONNECTIONS":
      return [];
    default:
      return state;
  }
};

export default connectionsReducer;

import { InputLabel, MenuItem, Select as MUISelect } from "@mui/material";
import React from "react";
import "./Select.css";

interface Props {
  inputLabel?: string;
  name?: string;
  options?: { value: string | number; label: string }[];
  value: string | undefined;
  onChange: (event) => void;
}

export const Select = ({
  inputLabel,
  name,
  options,
  onChange,
  ...props
}: Props) => {
  return (
    <div className="input-control">
      {inputLabel && (
        <InputLabel id="select-label" className="lol-label">
          {inputLabel}
        </InputLabel>
      )}
      <MUISelect
        style={{ borderRadius: "16px" }}
        className="lol-select"
        labelId="select-label"
        variant="outlined"
        onChange={onChange}
        {...props}
      >
        {options?.map((option) => (
          <MenuItem value={option.value}>{option.label}</MenuItem>
        ))}
      </MUISelect>
    </div>
  );
};

import React, { SyntheticEvent } from "react";
import { useHistory } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import searchIcon from "./search-icon.png";

function AutocompleteSearch({ listOfUsers }) {
  const history = useHistory();

  const goToUserPage = (event: SyntheticEvent<Element, Event>, value: any) => {
    history.push(`/user/${value.id}/videos`);
  };

  return (
    <>
      <Autocomplete
        id="users"
        options={listOfUsers}
        getOptionLabel={(option) =>
          `${option.first_name} ${option.last_name} (${option.email})`
        }
        onChange={goToUserPage}
        fullWidth
        size="small"
        renderInput={(params) => (
          <>
            <TextField
              sx={{ marginTop: "3px" }}
              {...params}
              label="Search users by name"
              className="search-textfield"
            />
            <img className="search-icon" src={searchIcon} width="16px" />
          </>
        )}
        className="search-input"
      />
    </>
  );
}

export default AutocompleteSearch;

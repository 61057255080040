import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "@redux-devtools/extension";

import rootReducer from "./reducers/_root.reducer";
import rootSaga from "./sagas/_root.saga";

const sagaMiddleware = createSagaMiddleware();

const middlewareList = [sagaMiddleware];

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewareList))
);

sagaMiddleware.run(rootSaga);

export default store;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone, { IFileWithMeta } from "react-dropzone-uploader";
import Swal from "sweetalert2";
import {
  Modal,
  Typography,
  Box,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  CircularProgress,
} from "@mui/material";
import { RootState } from "../../redux/reducers/_root.reducer";
import "./Uploader.css";
import uploadVideoImage from "./upload-video-icon.svg";
import uploadVideoImageBlack from "./upload-video-icon-black.svg";
import { Button } from "../../stories/Button";
import { useHistory } from "react-router-dom";
import axios from "axios";

function Uploader({ videoLabel }) {
  const user = useSelector((store: RootState) => store.user);
  const history = useHistory();
  useEffect(() => {
    dispatch({
      type: "GET_PROMPTS",
    });
    dispatch({
      type: "CLEAR_VIDEO",
    });
  }, []);

  const dispatch = useDispatch();
  const prompts = useSelector((store: RootState) => store.promptReducer);

  const video: any = useSelector((store: RootState) => store.videoReducer);
  const [videoPrompt, setVideoPrompt] = useState("");
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const swalWait = () => {
    Swal.fire({
      icon: "success",
      title: "Successful Upload",
      footer: "Video has been uploaded successfully",
    }).then(function () {
      history.push("/user/" + user.user?.id + "/videos");
    });
  };

  const handleSubmit = async (
    files: IFileWithMeta[],
    allFiles: IFileWithMeta[]
  ) => {
    const accessToken = localStorage.getItem("accessToken");

    if (videoPrompt === "") {
      setOpenVideoModal(false);
      Swal.fire({
        icon: "error",
        title: "Prompt Required",
        text: "Please choose a Prompt before uploading",
        customClass: "upload-prompt-dialog",
      });
    } else {
      setIsLoading(true);
      const f = files[0];
      const formData = new FormData();
      formData.set("prompt", videoPrompt);
      formData.append("video", f.file.slice());
      await axios.post("/api/video/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      allFiles.forEach((f) => f.remove());

      setOpenVideoModal(false);
      setVideoPrompt("");
      swalWait();
      setIsLoading(false);
    }
  };

  const handleChangeVideo = () => {
    dispatch({
      type: "CLEAR_VIDEO",
    });
  };

  const handleOpenVideoModal = () => {
    dispatch({ type: "GET_PROMPTS" });
    setOpenVideoModal(true);
  };

  const handleCloseVideoModal = () => {
    setVideoPrompt("");
    setOpenVideoModal(false);
  };
  return (
    <div className="upload">
      {video.file ? (
        <Box>
          <p>{video.file.name}</p>
          <Button
            onClick={handleChangeVideo}
            label="Remove Video"
            primary={true}
          />
        </Box>
      ) : (
        <>
          <div className="video-upload-mobile" onClick={handleOpenVideoModal}>
            <img width="22px" src={uploadVideoImageBlack} alt="" />
          </div>
          <Button
            onClick={handleOpenVideoModal}
            label={videoLabel}
            icon={<img src={uploadVideoImage} alt="" />}
            primary={true}
            iconPos="left"
            cssClass="video-upload-desktop"
          />
        </>
      )}
      <Modal
        open={openVideoModal}
        onClose={handleCloseVideoModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mb-1 text-center"
      >
        <Box className="upload-video__modal">
          <Typography
            id="modal-modal-title"
            className="upload-video__modal-title"
            variant="h6"
            component="h2"
          >
            Upload Video Here!
            <img
              src="/images/backgrounds/modal-close.png"
              onClick={handleCloseVideoModal}
              alt="close icon"
            />
          </Typography>
          {isLoading ? (
            <Box className="upload-video_loading-spinner">
              <CircularProgress />
            </Box>
          ) : (
            <>
              <FormControl className="upload-video__modal-prompt" fullWidth>
                <InputLabel id="demo-simple-select-label">Prompt</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  className="upload-video__modal-dropdown"
                  value={videoPrompt}
                  label="prompt"
                  onChange={(event) => setVideoPrompt(event.target.value)}
                >
                  {prompts.map((prompt) => (
                    <MenuItem key={prompt.id} value={prompt.id}>
                      {prompt.prompt}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Dropzone
                onSubmit={handleSubmit}
                maxFiles={1}
                canCancel={false}
                inputContent={(files, extra) =>
                  extra.reject
                    ? "Video files only"
                    : "Click or Drag 1 Video Here"
                }
                accept="video/*"
              />
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}
export default Uploader;

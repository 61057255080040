import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import SelectToShare from "./SelectToShare";
import CopyToClipboard from "./CopyToClipboard";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { RootState } from "../../redux/reducers/_root.reducer";
import { Button } from "../../stories/Button";
import "./widget.css";
import { useApi } from "../../hooks/useApi";
import Swal from "sweetalert2";

function ShareDialogBox({
  callback,
  video,
}: {
  callback?: () => void;
  video?: any;
}) {
  const shareData = useSelector(
    (store: RootState) => store.shareReducer.shareReducer
  );
  const user = useSelector((store: RootState) => store.user);

  const [open, setOpen] = React.useState(false);

  const [showShortLink, setShowShortLink] = React.useState(false);
  const dispatch = useDispatch();
  const { apiPost } = useApi();

  const handleClickOpen = () => {
    {
      callback ? callback() : null;
    }
    setOpen(true);
    shortenURL();
    getConnections();
  };

  const urlObj = {
    url: `${window.origin}/user/${user.user?.id}/videos?autoplay=${video.id}`,
    domain: "tinyurl.com",
  };

  const [shortenedURL, setShortenedURL] = React.useState("");

  const getConnections = () => {
    dispatch({ type: "GET_CONNECTIONS", payload: user.user?.id });
  };

  const shortenURL = () => {
    axios
      .post(`/api/link`, urlObj)
      .then((response) => {
        setShortenedURL(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = async () => {
    if (Object.keys(shareData).length === 0) {
      Swal.fire({
        title: "Error!",
        text: "Select recipient",
        icon: "error",
        confirmButtonText: "OK",
      });
      handleClose();
      return;
    }
    const res = await apiPost("/api/share", shareData);
    setOpen(false);
    if (res === "OK") {
      Swal.fire({
        title: "Success!",
        text: "Your video has been shared.",
        icon: "success",
        confirmButtonText: "OK",
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setShowShortLink(false);
  };

  return (
    <>
      <Button
        primary={true}
        onClick={handleClickOpen}
        label="Share"
        cssClass="share-button"
        icon={<ShareOutlinedIcon />}
        iconPos="right"
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className="share-dialog"
      >
        <DialogContent>
          <SelectToShare video={video} />
          {showShortLink ? (
            <CopyToClipboard url={shortenedURL} />
          ) : (
            <>
              <p>Or, to send a video link by text or email:</p>
              <Button
                onClick={() => {
                  setShowShortLink(true);
                }}
                cssClass="share-link-button"
                label="Give me a link"
              />
            </>
          )}
        </DialogContent>

        <DialogActions className="share-buttons">
          <Button onClick={handleClose} label="Cancel" />

          <Button onClick={handleSubmit} primary={true} label="Send video" />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ShareDialogBox;

import "./UserPage.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserVideos from "../UserVideos/UserVideos";
import Connections from "../Connections/Connections";
import VideoUploadPage from "../VideoUploadPage/VideoUploadPage";
import ProfilePicButton from "../_Widgets/ProfilePicButton";
import BannerDialog from "../_Widgets/Banner/BannerDialog";
import EditProfile from "../EditProfile/EditProfile";
import { Menu, Typography, Fab } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { RootState } from "../../redux/reducers/_root.reducer";
import { Connection } from "../../../models/connections";
import PersonIcon from "@mui/icons-material/Person";
import PhotoUploader from "../EditProfile/PhotoUploader";
import { Button } from "../../stories/Button";
import { styled, alpha } from "@mui/material/styles";
import { useApi } from "../../hooks/useApi";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
function UserPage() {
  const dispatch = useDispatch();

  const user = useSelector((store: RootState) => store.user);
  const searchedUser = useSelector(
    (store: RootState) => store.searchReducer?.searchedUser
  );
  const [connections, setConnections] = useState<Connection[]>();
  const params = useParams<{ view: string; userInParams: string }>();
  const view = params.view;
  const userInParams = Number(params.userInParams);
  const [noImage, setNoImage] = useState(false);
  const [menuPosition, setMenuPosition] = useState(null);
  const { apiGet } = useApi();

  const openRequestMenu = (event) => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX,
    });
  };

  const handleFriendClick = () => {
    setMenuPosition(null);

    dispatch({
      type: "POST_REQUEST",
      payload: { relationship: "friend", userB: userInParams },
    });
  };

  const handleFamilyClick = () => {
    setMenuPosition(null);

    dispatch({
      type: "POST_REQUEST",
      payload: { relationship: "family", userB: userInParams },
    });
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    dispatch({ type: "GET_SEARCHED_USER", payload: userInParams });
  }, [userInParams]);

  useEffect(() => {
    (async () => {
      const conn = await apiGet(`/api/connections/${userInParams}`);
      setConnections(conn);
    })();
  }, []);

  return (
    <>
      <div className="profile-header">
        <>
          {userInParams == user.user?.id ? (
            <>
              {view !== "edit" && (
                <div className="banner-block">
                  <img
                    className="bannerimage"
                    src={user.user?.banner_image}
                    alt="Banner image"
                  />

                  <BannerDialog />
                </div>
              )}
              <div
                className={`${
                  view !== "edit" ? "profile-img-div" : "profile-image__block"
                }`}
              >
                <img
                  className={`${noImage ? "no-image-active" : ""} profile-img`}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    setNoImage(true);
                  }}
                  src={user.user?.profile_image}
                  alt={`A picture of ${user.user?.first_name}`}
                />
                {noImage && (
                  <div className="no-image">
                    <PersonIcon />
                  </div>
                )}
                <div className={`${view !== "edit" ? "profilePicBtn" : ""}`}>
                  {view === "edit" && <PhotoUploader />}
                </div>
              </div>
            </>
          ) : (
            <>
              {view !== "edit" && (
                <img
                  className="bannerimage"
                  src={searchedUser.banner_image}
                  alt="Banner image"
                />
              )}
              <div className="profile-img-div">
                <img
                  className="profile-img"
                  src={searchedUser.profile_image}
                  alt={`A picture of ${searchedUser.first_name}`}
                />
              </div>

              <div className="searchAboutMe">
                <Typography variant="subtitle2">
                  {searchedUser.about_me}
                </Typography>
              </div>
            </>
          )}
        </>
        {view !== "edit" && (
          <div className="info-beneath-photos">
            <div className="name-and-location">
              <Typography className="user-details__name" variant="h5">
                {searchedUser?.first_name ? searchedUser?.first_name + " " : ""}
                {searchedUser?.last_name ? searchedUser?.last_name : ""}
              </Typography>

              <Typography className="user-details__location" variant="h6">
                {searchedUser?.city ? searchedUser?.city + ", " : ""}
                {searchedUser?.state ? searchedUser?.state?.toUpperCase() : ""}
              </Typography>
              {userInParams === user.user?.id && (
                <div className={`${view !== "edit" ? "profilePicBtn" : ""}`}>
                  {view !== "edit" && <ProfilePicButton />}
                </div>
              )}
              {userInParams != user.user?.id && (
                <>
                  {(!connections || connections.length === 0) && (
                    <Button
                      label="Connect"
                      primary={true}
                      onClick={handleClick}
                      cssClass="connect-button"
                    />
                  )}
                  {connections?.[0]?.pending === false && (
                    <Button label="Following" cssClass="connect-button" />
                  )}
                  {connections?.[0]?.pending === true && (
                    <Button label="Pending" cssClass="connect-button" />
                  )}

                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem
                      style={{ width: "100%" }}
                      onClick={() => {
                        handleFriendClick();
                        handleClose();
                      }}
                    >
                      Connect as Friends
                    </MenuItem>
                    <MenuItem
                      style={{ width: "100%" }}
                      onClick={() => {
                        handleFamilyClick();
                        handleClose();
                      }}
                    >
                      Connect as Family
                    </MenuItem>
                  </StyledMenu>
                </>
              )}
            </div>
          </div>
        )}
      </div>

      {userInParams == user.user?.id && (
        <>
          {view == "videos" && <UserVideos />}

          {view == "connections" && <Connections />}

          {view == "uploads" && <VideoUploadPage />}

          {view == "edit" && <EditProfile />}
        </>
      )}

      {userInParams != user.user?.id && (
        <>
          <UserVideos />
        </>
      )}
    </>
  );
}

export default UserPage;

import axios from "axios";
import Swal from "sweetalert2";

export const useApi = () => {
  const accessToken = localStorage.getItem("accessToken");

  const apiGet = async (url: string) => {
    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return response.data;
    } catch {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Something went wrong. Please try again.",
      });
    }
  };

  const apiDelete = async (url: string) => {
    try {
      await axios.delete(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
    } catch {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Something went wrong. Please try again.",
      });
    }
  };

  const apiPost = async (url: string, body?: any) => {
    try {
      const response = await axios.post(url, body, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return response.data;
    } catch {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Something went wrong. Please try again.",
      });
    }
  };

  return { apiGet, apiDelete, apiPost };
};

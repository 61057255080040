import { put, takeLatest } from "redux-saga/effects";
import axios from "axios";

function* getUsers(action) {
  const accessToken = localStorage.getItem("accessToken");
  try {
    const response = yield axios.get("/api/search", {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    yield put({
      type: "SET_LIST_OF_USERS",
      payload: { listOfUsers: response.data, currentUserId: action.payload },
    });
  } catch (error) {
    console.log("video saga GET", error);
  }
}

function* getSearchedUser(action) {
  try {
    const response = yield axios.get(`/api/search/${action.payload}`);
    yield put({ type: "SET_SEARCHED_USER", payload: response.data[0] });
  } catch (error) {
    console.log("search saga GET", error);
  }
}

function* searchSaga() {
  yield takeLatest("GET_USERS", getUsers);
  yield takeLatest("GET_SEARCHED_USER", getSearchedUser);
}

export default searchSaga;

import { Typography } from '@mui/material';
import React from 'react';
import './pageTitle.css';

interface TitleProps {
  pageTitle?: boolean;
  label: string;
  onClick?: () => void;
}
export const PageTitle = ({
  pageTitle = false,
  label,
  ...props
}: TitleProps) => {
  const mode = pageTitle && 'lol-page--title';
  return (
    <Typography
      variant='h2'
      className={['lol-title', mode].join(' ')}
      {...props}
    >
      {label}
    </Typography>
  );
};

// Imports
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";

// Styling
import { ThemeProvider } from "@mui/material/styles";
import customTheme from "../_customTheme/theme";
import "./App.css";

// Routes
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import AboutPage from "../AboutPage/AboutPage";
import UserPage from "../UserPage/UserPage";
import InfoPage from "../InfoPage/InfoPage";
import VideoWatchPage from "../VideoWatchPage/VideoWatchPage";
import ManageLibrary from "../ManageLibrary/ManageLibrary";
import VideoUploadPage from "../VideoUploadPage/VideoUploadPage";
import EditProfile from "../EditProfile/EditProfile";
import { RootState } from "../../redux/reducers/_root.reducer";
import LoginRegistrationContainer from "../LoginRegistrationCotainer/LoginRegistrationContainer";
import LandingPage from "../LandingPage/LandingPage";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch({ type: "GET_USER" });
  }, []);

  return (
    <ThemeProvider theme={customTheme}>
      <div
        className={`${
          location.pathname !== "/login" &&
          location.pathname !== "/registration"
            ? "page-wrapper"
            : ""
        }`}
      >
        {location.pathname === "/videoWatchPage" ||
        location.pathname === "/login" ||
        location.pathname === "/registration" ? null : (
          <Nav forgotPasswordCheck={false} />
        )}
        <Switch>
          <Redirect exact from="/" to="/landingpage" />
          <Route exact path="/landingpage">
            <LandingPage />
          </Route>
          <Route exact path="/about">
            <AboutPage />
          </Route>
          <ProtectedRoute exact path="/user/:userInParams/:view">
            <UserPage />
          </ProtectedRoute>
          <ProtectedRoute exact path="/info">
            <InfoPage />
          </ProtectedRoute>
          <ProtectedRoute exact path="/edit/:id">
            <EditProfile />
          </ProtectedRoute>
          <Route exact path="/login">
            <LoginRegistrationContainer page="login" />
          </Route>

          <Route exact path="/registration">
            <LoginRegistrationContainer page="register" />
          </Route>

          <Route exact path="/videoUploadPage">
            <VideoUploadPage />
          </Route>

          <Route exact path="/videoWatchPage">
            <VideoWatchPage />
          </Route>

          <ProtectedRoute exact path="/manageLibrary">
            <ManageLibrary />
          </ProtectedRoute>
          <Route>
            <h1>404</h1>
          </Route>
        </Switch>
        {location.pathname === "/login" ||
        location.pathname === "/registration" ? null : (
          <Footer />
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Box, Link } from "@mui/material";
import "../LoginForm/LoginForm.css";
import { RootState } from "../../redux/reducers/_root.reducer";
import { PageTitle } from "../../stories/PageTitle";
import { Button } from "../../stories/Button";
import { InputField } from "../../stories/InputField";
import { WebAuth } from "auth0-js";

interface pageProp {
  setForgotPasswordCheck: (
    value: boolean | ((prevVar: boolean) => boolean)
  ) => void;
}

const webAuth = new WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN ?? "",
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID ?? "",
  redirectUri: window.location.origin,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  responseType: "token",
});

const ForgotPassword: React.FC<pageProp> = (props: pageProp) => {
  const [isResetPasswordSubmitted, setIsResetPasswordSubmitted] =
    useState(false);
  const [username, setUsername] = useState("");
  const errors = useSelector((store: RootState) => store.errors);
  const dispatch = useDispatch();

  const onSubmitForgotPassword = (event) => {
    event.preventDefault();

    if (username) {
      webAuth.changePassword(
        {
          connection: "Username-Password-Authentication",
          email: username,
        },
        function (err, resp) {
          if (err) {
            console.log(err.message);
          } else {
            setIsResetPasswordSubmitted(true);
            console.log(resp);
          }
        }
      );
    } else {
      dispatch({ type: "FORGOTPASSWORD_INPUT_ERROR" });
    }
    setUsername("");
  };

  return (
    <Box
      className="loginFormBox"
      sx={{
        height: "75%",
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      {isResetPasswordSubmitted ? (
        <Box className="formPanel">
          <PageTitle pageTitle={true} label="Forgot Password" />
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                color: "#71717A",
              }}
            >
              Check your inbox for a link to reset your password.
            </p>
          </Box>
        </Box>
      ) : (
        <form className="formPanel" onSubmit={onSubmitForgotPassword}>
          <PageTitle pageTitle={true} label="Forgot Password" />
          <p className="login-form__details">
            Please enter your email address to request password reset.
          </p>
          {errors.loginMessage && (
            <h3 className="alert" role="alert">
              {errors.loginMessage}
            </h3>
          )}

          <div>
            <InputField
              placeholder="Email Address"
              type="email"
              name="username"
              required={true}
              backgroundColor="white"
              value={username}
              InputProps={{
                onChange: (event) => {
                  setUsername(event.target.value);
                },
              }}
            />
          </div>
          <div className="centerLogin">
            <Button
              primary={true}
              type="submit"
              label="Submit"
              cssClass="login-button"
            />
          </div>
        </form>
      )}
    </Box>
  );
};

export default ForgotPassword;

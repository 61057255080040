import UserVideoItem from "../UserVideoItem/UserVideoItem";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Container, Grid } from "@mui/material";
import { ToggleButton } from "@mui/material";
import { ToggleButtonGroup } from "@mui/material";
import { RootState } from "../../redux/reducers/_root.reducer";
import "./UserVideos.css";
import { SharedVideo, Video } from "../../../models/video";
import { format } from "date-fns";
import { useApi } from "../../hooks/useApi";

function UserVideos() {
  const dispatch = useDispatch();
  const user = useSelector((store: RootState) => store.user);
  const [videos, setVideos] = useState<Video[]>([]);
  const [toggle, setToggle] = React.useState("left");
  const [myVideos, setMyVideos] = useState(false);
  const [sharedWithMe, setSharedWithMe] = useState(false);
  const [sharedByMe, setSharedByMe] = useState(false);
  const params = useParams<{ userInParams: string }>();
  const userInParams = Number(params.userInParams);
  const isOwnProfile = user.user?.id == userInParams;
  const [sharedByMeVideos, setSharedByMeVideos] = useState<SharedVideo[]>([]);
  const [sharedWithMeVideos, setSharedWithMeVideos] = useState<SharedVideo[]>(
    []
  );
  const { apiGet, apiDelete } = useApi();
  const location = useLocation();
  const autoplay = location.search.match(/\?autoplay=([^&]+)/)?.[1] ?? "";
  const handleToggle = (newToggle: string) => {
    setToggle(newToggle);
  };

  const handleMyVideos = async () => {
    setMyVideos(true);
    setSharedWithMe(false);
    setSharedByMe(false);
    if (isOwnProfile) {
      const response = await apiGet("/api/video/userVideos");
      setVideos(response);
    } else {
      const response = await apiGet(
        `/api/video/user/${userInParams}${
          autoplay ? `?autoplay=${autoplay}` : ""
        }`
      );
      setVideos(response);
    }
  };

  const handleSharedWithMe = async () => {
    setMyVideos(false);
    setSharedWithMe(true);
    setSharedByMe(false);
    const response = await apiGet("/api/video/sharedwithme");
    setSharedWithMeVideos(response);
  };

  const handleSharedByMe = async () => {
    setMyVideos(false);
    setSharedWithMe(false);
    setSharedByMe(true);
    const response = await apiGet("/api/video/sharedbyme");
    setSharedByMeVideos(response);
  };

  useEffect(() => {
    handleMyVideos();
  }, []);

  const deleteSharedVideo = async (videoId: string) => {
    await apiDelete(`/api/video/shared/${videoId}`);
  };

  return (
    <>
      <Container className="video-container">
        <div className="videos-tab">
          <ToggleButtonGroup
            value={toggle}
            className="videoToggle"
            size="small"
            exclusive
            onChange={(event) => {
              handleToggle("event.target.value");
            }}
            aria-label="connections"
          >
            <ToggleButton
              onClick={() => {
                handleMyVideos();
              }}
              value="myVideos"
              aria-label="myVideos"
              className={`${myVideos ? "active" : ""} videos-tab__item`}
            >
              <h3>My Videos</h3>
            </ToggleButton>

            {isOwnProfile && (
              <ToggleButton
                onClick={() => {
                  handleSharedWithMe();
                }}
                value="sharedVideos"
                aria-label="sharedVideos"
                className={`${sharedWithMe ? "active" : ""} videos-tab__item`}
              >
                <h3>Shared With Me</h3>
              </ToggleButton>
            )}

            {isOwnProfile && (
              <ToggleButton
                onClick={() => {
                  handleSharedByMe();
                }}
                value="sharedVideos"
                aria-label="sharedVideos"
                className={`${sharedByMe ? "active" : ""} videos-tab__item`}
              >
                <h3>Shared By Me</h3>
              </ToggleButton>
            )}
          </ToggleButtonGroup>
        </div>

        {myVideos && (
          <Grid container spacing={1}>
            {videos?.map((video, i) => {
              return (
                <UserVideoItem
                  key={i}
                  video={video}
                  onDelete={
                    isOwnProfile
                      ? () =>
                          dispatch({ type: "DELETE_VIDEO", payload: video.id })
                      : undefined
                  }
                  shareButtonVisible={isOwnProfile}
                  permissionsVisible={isOwnProfile}
                  autoPlay={autoplay.toString() === video.id.toString()}
                />
              );
            })}
          </Grid>
        )}

        {sharedWithMe && (
          <Grid container spacing={1}>
            {sharedWithMeVideos?.map((video, i) => {
              return (
                <UserVideoItem
                  key={i}
                  video={video}
                  shareMessage={`Shared by ${video.first_name} ${
                    video.last_name
                  } (${video.email}) on ${format(
                    new Date(video.shared_on),
                    "MM/dd/yyyy"
                  )}`}
                  onDelete={async () => {
                    await deleteSharedVideo(video.id);
                    handleSharedWithMe();
                  }}
                />
              );
            })}
          </Grid>
        )}

        {sharedByMe && (
          <Grid container spacing={1}>
            {sharedByMeVideos?.map((video, i) => {
              return (
                <UserVideoItem
                  key={i}
                  video={video}
                  shareMessage={`Shared with ${video.first_name} ${
                    video.last_name
                  } (${video.email}) on ${format(
                    new Date(video.shared_on),
                    "MM/dd/yyyy"
                  )}`}
                  shareButtonVisible
                />
              );
            })}
          </Grid>
        )}
      </Container>
    </>
  );
}

export default UserVideos;

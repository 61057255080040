import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Card } from "@mui/material";

function BannerItem({ banner, selectedBanner }) {
  const [selectedImg, setSelectedImg] = useState();
  const dispatch = useDispatch();

  const handleChange = (imgUrl) => {
    setSelectedImg(imgUrl);
    dispatch({ type: "SET_BANNER_REDUCER", payload: imgUrl });
  };

  useEffect(() => {
      setSelectedImg(selectedBanner?.banner || selectedBanner);
  }, [selectedBanner]);

  return (
    <Card className={`banner-item-container ${selectedImg === banner.url && 'active' }`} onClick={() => handleChange(banner.url)}>
      <img
        src={banner.url}
        alt={`A picture of ${banner.name}`}
        className="banner-item-img"
      />
      <div className="banner-item-card">
        {banner.name}
      </div>
    </Card >
  );
}

export default BannerItem;

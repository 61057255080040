import { put, takeLatest } from "redux-saga/effects";
import axios from "axios";
function* getPermission(action) {
  const video_id = action.payload;
  try {
    const permissions = yield axios.get(`/api/permission/${video_id}`);
    yield put({
      type: "SET_PERMISSION",
      payload: permissions.data[0]?.permission,
    });
  } catch (error) {
    console.log("permission saga GET", error);
  }
}

function* updatePermission(action) {
  const video_id = action.payload.video_id;
  const permission = action.payload.permission;
  const accessToken = localStorage.getItem("accessToken");

  try {
    yield axios.put(
      `/api/permission/${video_id}`,
      { permission },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put({ type: "GET_PERMISSION", payload: video_id });
    yield put({ type: "GET_MY_VIDEOS" });
  } catch (error) {
    console.log("permission saga PUT", error);
  }
}

function* permissionSaga() {
  yield takeLatest("GET_PERMISSION", getPermission);
  yield takeLatest("UPDATE_PERMISSION", updatePermission);
}

export default permissionSaga;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Fab } from "@mui/material";
import { VscEdit } from "react-icons/vsc";
import { RootState } from "../../redux/reducers/_root.reducer";
import { Button } from "../../stories/Button";

function ProfileEditButton() {
  const user = useSelector((store: RootState) => store.user);
  const history = useHistory();

  const handleEditProfile = () => {
    history.push(`/user/${user.user?.id}/edit`);
  };

  const handleMouseIn = () => {
    setHover(true);
  };

  const handleMouseOut = () => {
    setHover(false);
  };

  const [hover, setHover] = useState(false);
  return (
    <Button
      onMouseOver={handleMouseIn}
      onMouseOut={handleMouseOut}
      onClick={handleEditProfile}
      primary={true}
      label="Edit Profile"
      icon={<VscEdit />}
      iconPos="left"
      cssClass="edit-profile-button"
    />
  );
}

export default ProfileEditButton;

import { combineReducers } from "redux";
import errors from "./errors.reducer";
import user from "./user.reducer";
import searchReducer from "./search.reducer";
import videoReducer from "./video.reducer";
import connectionsReducer from "./connections.reducer";
import permissionReducer from "./permission.reducer";
import promptReducer from "./prompt.reducer";
import shareReducer from "./share.reducer";
import bannerReducer from "./banner.reducer";
import photoReducer from "./photo.reducer";
const rootReducer = combineReducers({
  errors,
  user,
  videoReducer,
  connectionsReducer,
  permissionReducer,
  promptReducer,
  searchReducer,
  shareReducer,
  bannerReducer,
  photoReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

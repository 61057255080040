import { createTheme } from '@mui/material/styles';
import { red } from "@mui/material/colors";

const primaryColor = "#667b68";
const secondaryColor = "#a3b899";
const dangerColor = red[900];

const customTheme = createTheme({
  typography: {
    fontFamily: [
      "Blinker",
      "Arial",
      "sans-serif",
    ].join(",")
  },
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    danger: {
      main: dangerColor,
    },
  },
  components: {
    MuiFab: {
      styleOverrides: {
        primary: {
          backgroundColor: primaryColor,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        primary: {
          backgroundColor: primaryColor,
        },
      },
    },
  }
});
export default customTheme
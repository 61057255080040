import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./LandingPage.css";
import { Container, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/_root.reducer";

function LandingPage() {
  const history = useHistory();
  const user = useSelector((store: RootState) => store.user);
  const location = useLocation();
  const autoplay = location.search.match(/\?autoplay=(.)/)?.[1];

  useEffect(() => {
    // Grab token from url in the case of a callback from Auth0
    const hash = location.hash;
    const accessTokenMatch = hash.match(/\#access_token=([^&]+)/);
    if (accessTokenMatch) {
      localStorage.setItem("accessToken", accessTokenMatch[1]);
    }
  }, [location]);

  useEffect(() => {
    if (!user.isLoading && user.user?.id) {
      history.push(`/user/${user.user.id}/videos${location.search}`);
    } else if (!user.isLoading && !user.user?.id) {
      history.push("/login");
    }
  }, [user]);

  return (
    <Container>
      <CircularProgress size={64} />
    </Container>
  );
}

export default LandingPage;

import { useEffect } from "react";
import ReactPlayerComponent from "../_Widgets/ReactPlayerComponent";
import { useDispatch } from "react-redux";
import "./VideoWatchPage.css";
import { Container } from "@mui/material";

function VideoWatchPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "GET_VIDEO_BY_ID" });
  }, []);
  return (
    <Container className="videoWatch">
      <h2>If you could do something to change humanity, what would it be?</h2>
      <div id="video">
        <ReactPlayerComponent
          videoURL={"https://d2qw0j2prooaok.cloudfront.net/1336144.mp4"}
        />
      </div>
    </Container>
  );
}

export default VideoWatchPage;

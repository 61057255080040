import axios from "axios";
import { put, takeLatest } from "redux-saga/effects";

// worker Saga: will be fired on "GET_USER" actions
// updated getUser to getUser
function* getUser() {
  yield put({ type: "IS_LOADING" });
  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken) {
    yield put({ type: "DONE_LOADING" });
    return;
  }
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = yield axios.get("/api/user", config);
    yield put({ type: "SET_USER", payload: response.data });
  } catch (error) {
    console.log("user saga GET", error);
    yield put({ type: "DONE_LOADING" });
  }
}
function* userSaga() {
  yield takeLatest("GET_USER", getUser);
}

export default userSaga;

import { put, takeLatest } from "redux-saga/effects";
import { WebAuth } from "auth0-js";

const webAuth = new WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN ?? "",
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID ?? "",
  redirectUri: window.location.origin,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  responseType: "token",
});

function* loginUser(action) {
  try {
    yield put({ type: "LOGIN_START" });
    const { username, password } = action.payload;
    yield webAuth.login(
      {
        username,
        password,
      },
      () => null
    );
    yield put({ type: "LOGIN_SUCCESS" });
  } catch (error) {
    yield put({ type: "LOGIN_ERROR", error });
  }
}

function* logoutUser() {
  try {
    localStorage.removeItem("accessToken");
    yield put({ type: "UNSET_USER" });
    yield put({ type: "CLEAR_BANNER_REDUCER" });
    yield put({ type: "CLEAR_PROMPTS" });
    yield put({ type: "CLEAR_VIDEOS" });
    yield put({ type: "CLEAR_LIST_OF_USERS" });
    yield put({ type: "CLEAR_SEARCHED_USER" });
    webAuth.logout({ returnTo: `${window.location.origin}/login` });
  } catch (error) {
    console.log("Error with user logout:", error);
  }
}

function* loginSaga() {
  yield takeLatest("LOGOUT", logoutUser);
  yield takeLatest("LOGIN", loginUser);
}

export default loginSaga;
